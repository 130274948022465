// ==============================
// Archive
// =============================

.archive {
  margin: $archive-margin;
  max-width: $archive-max-width;

  .archive-title {
    font-family: $global-serif-font-family;

    &.tag,
    &.category {
      margin: 15px 0;
    }

    .archive-name {
      margin: 0;
      display: inline-block;
      font-weight: 400;
      font-size: $archive-name-font-size;
      line-height: $archive-name-font-size + 2px;
    }

    .archive-post-counter {
      color: $dark-gray;
    }
  }

  .collection-title {
    font-family: $global-serif-font-family;
  
    .archive-year {
      margin: 15px 0;
      font-weight: 400;
      font-size: $collection-title-font-size;
      line-height: $collection-title-font-size + 2px;
    }
  }

  .archive-post {
    padding: $archive-post-padding;
    border-left: $archive-post-border-left;

    .archive-post-time {
      margin-right: 10px;
      color: $dark-gray;
    }

    .archive-post-title {
      
      .archive-post-link {
        color: $theme-color;
      }
    }

    &::first-child {
      margin-top: 10px;
    }

    &:hover {
      border-left: $archive-post-hover-border-left;
      transition: $archive-post-hover-transition;
      transform: $archive-post-hover-transform;

      .archive-post-time {
        color: darken($dark-gray, 10%);
      }

      .archive-post-title .archive-post-link {
        color: darken($theme-color, 10%);
      }
    }
  }
}

@include max-screen() {
  .archive {
    margin-left: auto;
    margin-right: auto;

    .archive-title .archive-name {
      font-size: $archive-name-font-size - 4px;
    }

    .collection-title .archive-year {
      margin: 10px 0;
      font-size: $collection-title-font-size - 4px;
    }

    .archive-post {
      padding: $archive-post-mobile-padding;

      .archive-post-time {
        font-size: $archive-post-mobile-time-font-size;
        display: block;
      }
    }
  }
}

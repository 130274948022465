.post-outdated {
  .hint {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px 10px;
    border-left: 4px solid rgb(66, 172, 243);
    background-color: rgb(239, 245, 255);
    border-color: rgb(66, 172, 243);
  }

  .warn {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px 10px;
    border-left: 4px solid #f9cf63;
    background-color: #ffffc0;
    border-color: #f9cf63;
  }
}





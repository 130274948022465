// ==============================
// Logo
// =============================

.logo-wrapper {
  float: left;

  .logo {
    font-size: $logo-font-size;
    font-family: $logo-font-family;
  }
}

@include max-screen() {
  .logo-wrapper {
    display: none;
  }
}

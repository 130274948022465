.post-toc {
  position: absolute;
  width: $post-toc-width;
  margin-left: $post-toc-margin-left;
  padding: 10px;
  font-family: $global-serif-font-family;
  border-radius: 5px;
  background: $post-toc-backgroud;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.125);
  word-wrap: break-word;
  box-sizing: border-box;

  .post-toc-title {
    margin: 0 10px;
    font-size: $post-toc-title-size;
    font-weight: 400;
    text-transform: uppercase;
  }

  .post-toc-content {
    font-size: $post-toc-content;

    &.always-active ul {
      display: block;
    }

    >nav>ul {
      margin: 10px 0;
    }

    ul {
      padding-left: 20px;
      list-style: $post-toc-list-style;

      ul {
        padding-left: 15px;
        display: none;
      }

      .has-active > ul {
        display: block;
      }
    }

    .toc-link.active {
      color: $theme-color;
    }
  }
}

@include max-screen($toc-max-sreen-width) {
  .post-toc {
    display: none;
  }
}

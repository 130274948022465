// ==============================
// Iconfont
// ==============================

@font-face {
  font-family: 'iconfont';

  src: url('../fonts/iconfont/iconfont.eot');
  src: url('../fonts/iconfont/iconfont.eot#iefix') format('embedded-opentype'), // not '?#iefix', because webpack will add '?hash=[hash]'
  url('../fonts/iconfont/iconfont.woff') format('woff'),
  url('../fonts/iconfont/iconfont.ttf') format('truetype'),
  url('../fonts/iconfont/iconfont.svg#iconfont') format('svg');
}

%base-iconfont {
  font-family: "iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-text-stroke-width: 0.2px;
  cursor: pointer;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  font-feature-settings: "liga";
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconfont {
  @extend %base-iconfont;
}

/* Social Icon */
.icon-bilibili:before {
  content: "\e900";
  font-size: .9em;
  position: relative;
  top: -4px;
}
.icon-instagram:before {
  font-size: .95em;
  content: "\e611";
  position: relative;
  top: 1px;
}
.icon-douban:before {
  content: "\e610";
  position: relative;
  top: 2px;
}
.icon-tumblr:before {
  content: "\e69f";
  font-size: .85em;
  position: relative;
  top: -2px;
}
.icon-linkedin:before {
  content: "\e60d";
  position: relative;
  top: -2px;
}
.icon-twitter:before {
  content: "\e600";
}
.icon-weibo:before {
  content: "\e602";
  position: relative;
  top: 2px;
}
.icon-stack-overflow:before {
  content: "\e902";
  font-size: .85em;
  position: relative;
  top: -4px;
}
.icon-email:before {
  content: "\e605";
  position: relative;
  top: -2px;
}
.icon-facebook:before {
  content: "\e601";
  font-size: .95em;
  position: relative;
  top: -2px;
}
.icon-gitlab:before {
  content: "\e901";
  font-size: .9em;
  position: relative;
  top: -4px;
}
.icon-github:before {
  content: "\e606";
  position: relative;
  top: -1px;
}
.icon-rss:before {
  content: "\e604";
}
.icon-google:before {
  content: "\e609";
  position: relative;
  top: 2px;
}
.icon-zhihu:before {
  content: "\e607";
  font-size: .9em;
}
.icon-pocket:before {
  content: "\e856";
  position: relative;
  top: 2px;
}

/* Generic Icon */
.icon-heart:before {
  content: "\e608";
}
.icon-right:before {
  content: "\e60a";
}
.icon-left:before {
  content: "\e60b";
}
.icon-up:before {
  content: "\e60c";
}
.icon-close:before {
  content: "\e60f";
}
.icon-link:before {
  content: "\e909";
}

/* Admonition Icon */
/*
.icon-chevron-down:before {
  content: "\e908";
}
.icon-format-quote:before {
  content: "\e904";
}
.icon-pencil:before {
  content: "\e903";
}
.icon-list-numbered:before {
  content: "\e9b9";
}
.icon-list:before {
  content: "\e9bb";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-question:before {
  content: "\ea09";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-fire:before {
  content: "\e905";
}
.icon-danger:before {
  content: "\e905";
}
.icon-flame:before {
  content: "\e905";
}
.icon-hot:before {
  content: "\e905";
}
.icon-bulb:before {
  content: "\e906";
}
*/

// ==============================
// Post
// ==============================

.posts {
  margin-bottom: $post-list-margin-bottom;
  border-bottom: $post-border;
}

.post {
  padding: $post-padding;

  & + .post {
    border-top: $post-border;
  }

  @import '_post/header';
  @import '_post/toc';
  @import '_post/content';
  @import '_post/copyright';
  @import '_post/reward';
  @import '_post/footer';
  @import '_post/outdated';
}
